export const constants: any = {
  baseUrl: "https://referral.api.doorvi.co",
  appName: "DoorVi",
  appIds: { doorvi: "6603c5dc0a94cc79670700b8" },
  apiKey: {
    doorvi: "AIzaSyAZobD4J-psemk0NBrgO_w6hiEqrsEbxvM",
  },
  currency_symbols: {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    VND: "₫", // Vietnamese Dong
  },
};
